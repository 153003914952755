<template>
  <div>
    <v-layout wrap justify-center>
      <v-snackbar v-model="showSnackBar" color="black">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: white">
              {{ msg }}
            </span>
          </v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: white">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <vue-element-loading
        :active="appLoading"
        :is-full-screen="true"
        background-color="#FFFFFF"
        color="#283e51"
        spinner="spinner"
      />
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs7 pt-8 text-left>
        <span style="font-size: 25px; font-weight: bolder">BOOKINGS</span>
      </v-flex>

      <v-flex xl3 lg2 md3 pt-6 pr-4 sm3 xs12 align-self-center>
        <v-menu
          ref="menu1"
          v-model="menu1"
          :close-on-content-click="false"
          max-width="290"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              clearable
              dense
              outlined
              readonly
              v-model="fromDate"
              v-bind="attrs"
              background-color="white"
              v-on="on"
              @click:clear="from = null"
              style="color: black"
            >
              <template v-slot:append>
                <v-icon color="black">mdi-chevron-down</v-icon>
              </template>
              <template v-slot:label>
                <span
                  class="custom-label-text"
                  style="color: black; font-size: 14px"
                >
                  From Date</span
                >
              </template>
            </v-text-field>
          </template>
          <v-date-picker
            v-model="fromDate"
            color="#13736f"
            @change="menu1 = false"
          ></v-date-picker>
        </v-menu>
      </v-flex>
      <v-flex xl3 lg2 md3 pt-6 pr-1 sm3 xs12 align-self-center>
        <v-menu
          ref="menu2"
          v-model="menu2"
          :close-on-content-click="false"
          max-width="290"
          elevation="0"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              clearable
              label="To Date"
              readonly
              dense
              background-color="white"
              color="#13736f"
              outlined
              v-model="toDate"
              v-bind="attrs"
              v-on="on"
              @click:clear="from = null"
            >
              <template v-slot:append>
                <v-icon color="black">mdi-chevron-down</v-icon>
              </template>
              <template v-slot:label>
                <span
                  class="custom-label-text"
                  style="color: black; font-size: 14px"
                >
                  To Date</span
                >
              </template>
            </v-text-field>
          </template>
          <v-date-picker
            v-model="toDate"
            color="#13736f"
            @change="menu2 = false"
            :min="fromDate"
          ></v-date-picker>
        </v-menu>
      </v-flex>
    </v-layout>
    <v-layout v-for="(item, i) in list" :key="i" wrap justify-center>
      <v-flex xs12 md11 pt-3>
        <v-card outlined class="pa-3">
          <v-layout wrap justify-center>
            <v-flex xs12>
              <v-layout wrap pa-2>
                <v-flex xs4 lg2 pl-2>
                  <v-img
                    height="100%"
                    contain
                    src="../../assets/Images/ibimage12.jpg"
                  ></v-img>
                </v-flex>
                <v-flex xs8 lg10 pl-4>
                  <v-layout wrap justify-start>
                    <v-flex xs12 text-left pt-4 class="mainfont">
                      <v-layout wrap justify-start>
                        <v-flex xs3>
                          <span style="font-size: 17px; font-weight: bold">
                            IB Name&nbsp;:&nbsp;
                          </span>
                          <span>
                            {{ item.accommodationDetails.accName }}
                          </span>
                        </v-flex>
                        <v-flex xs3>
                          <span style="font-size: 17px; font-weight: bold">
                            Range&nbsp;:&nbsp;
                          </span>
                          <span>
                            {{ item.rangeDetails.officeName }}
                          </span>
                        </v-flex>
                        <v-flex xs12 md6 text-left>
                          <span class="mainfont" style="font-size: 17px">
                            <span style="font-size: 17px; font-weight: bold">
                              Booked Date&nbsp;:&nbsp;</span
                            >

                            {{ formatDate(item.fromDate) }} -
                            {{ formatDate(item.toDate) }}
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex
                      xs12
                      md3
                      text-left
                      py-2
                      v-if="
                        item && item.guestDetail && item.guestDetail.guestName
                      "
                    >
                      <span class="mainfont" style="font-size: 17px">
                        <span style="font-size: 17px; font-weight: bold">
                          Reserved For&nbsp;:&nbsp;</span
                        >
                        <span class="bold-heading">{{
                          item.guestDetail.guestName
                        }}</span>
                      </span>
                    </v-flex>

                    <v-flex
                      xs12
                      md3
                      text-left
                      py-2
                      v-if="item && item.priority"
                    >
                      <span class="mainfont" style="font-size: 17px">
                        <span style="font-size: 17px; font-weight: bold">
                          Priority &nbsp;:
                        </span>
                        {{ item.priority }}
                      </span>
                    </v-flex>

                    <v-flex
                      xs12
                      md3
                      text-left
                      py-2
                      v-if="item && item.reqRooms"
                    >
                      <span class="mainfont" style="font-size: 17px">
                        <span style="font-size: 17px; font-weight: bold">
                          Required Rooms&nbsp;:&nbsp;</span
                        >
                        {{ item.reqRooms }}
                      </span>
                    </v-flex>

                    <v-flex
                      xs12
                      md3
                      text-left
                      py-2
                      v-if="
                        item &&
                        item.guestDetail &&
                        item.guestDetail.guestMobileNo
                      "
                    >
                      <span class="mainfont" style="font-size: 17px">
                        <span style="font-size: 17px; font-weight: bold">
                          Phone&nbsp;:&nbsp;</span
                        >
                        <span class="bold-heading">{{
                          item.guestDetail.guestMobileNo
                        }}</span>
                      </span>
                    </v-flex>

                    <v-flex
                      xs12
                      md3
                      text-left
                      v-if="item && item.adminApprovalStatus"
                    >
                      <span class="mainfont" style="font-size: 17px">
                        <span style="font-size: 17px; font-weight: bold">
                          Approval Status&nbsp;:
                        </span>

                        {{ item.adminApprovalStatus }}
                      </span>
                    </v-flex>

                    <v-flex
                      xs12
                      md3
                      text-left
                      v-if="item && item.payments.amount"
                    >
                      <span class="mainfont" style="font-size: 17px">
                        <span style="font-size: 17px; font-weight: bold">
                          Amount&nbsp;:
                        </span>

                        {{ item.payments.amount }}Rs
                      </span>
                    </v-flex>

                    <v-flex
                      xs12
                      md3
                      text-left
                      v-if="item && item.payments && item.payments.status"
                    >
                      <span class="mainfont" style="font-size: 17px">
                        <span style="font-size: 17px; font-weight: bold">
                          Payment Status&nbsp;:
                        </span>
                        <span style="color: green">{{
                          item.payments.status
                        }}</span>
                      </span>
                    </v-flex>

                    <!-- <v-flex xs12 md3 text-left >
                        <span class="mainfont" style="font-size: 17px">
                          <span style="font-size: 17px;font-weight: bold;"> No : of children&nbsp;: </span>
  
                          {{ item.guestDetail.noofchildren }}
                        </span>
                      </v-flex>
                      <v-flex xs12 md3 text-left>
                        <span class="mainfont" style="font-size: 17px">
                          <span style="font-size: 17px;font-weight: bold;"> No: of Adults&nbsp;: </span>
  
                          {{ item.guestDetail.noofadults }}
                        </span>
                      </v-flex> -->
                  </v-layout>
                  <!-- <v-layout wrap justify-start v-if="item && item.adminApprovalStatus && item.adminApprovalStatus === 'Success' && item.paymentDetails && item.paymentDetails.status !== 'Success'">
                      <v-flex xs12>
                        <span style="color: red">Note: Payment must be completed before the booking date ({{ formatDate(item.fromDate) }}) to avail of the rooms.</span>
                      </v-flex>
                    </v-layout> -->
                  <v-layout wrap justify-start>
                    <v-spacer></v-spacer>
                    <!-- <v-flex
                        v-if="item.isExpired === 'No'"
                        xs6
                        lg2
                        align-self-end
                        text-right
                      >
                        <v-btn
                          class="text-capitalize"
                          @click="(editdialog = true), (edit = item)"
                          color="#D95D39"
                        >
                          <span style="color: white">Edit</span>
                        </v-btn>
                      </v-flex> -->
                    <!-- <v-flex xs6 lg2 align-self-end text-right v-if="item && item.adminApprovalStatus && item.adminApprovalStatus === 'Success' && item.paymentDetails && item.paymentDetails.status !== 'Success'">
                        <v-btn
                          class="text-capitalize"
                          color="#3cb371"
                          @click="openPaymentDialog(item)"
                        >
                          <span style="color: white">Pay Now!</span>
                        </v-btn>
                      </v-flex> -->
                    <v-flex xs6 lg2 align-self-end text-right>
                      <v-btn
                        class="text-capitalize"
                        @click="getInfo(item._id), (viewdialog = true)"
                        color="#3D5395"
                      >
                        <span style="color: white">View more</span>
                      </v-btn>
                    </v-flex>
                    <!-- <v-flex
                        v-if="item.isExpired === 'No' && item && item.paymentDetails && item.paymentDetails.status !== 'Success'"
                        xs12
                        sm6
                        lg3
                        align-self-end
                        text-right
                      >
                        <v-btn
                          class="text-capitalize"
                          @click="
                            setCurIdToDelete(item._id), (deletedialog = true)
                          "
                          color="red"
                        >
                          <span style="color: white">Cancel Reservation</span>
                        </v-btn>
                      </v-flex> -->
                  </v-layout>
                </v-flex>
              </v-layout>
              <v-divider></v-divider>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>

    <v-layout wrap v-if="list && list.length > 0">
      <v-flex xs12 v-if="list && list.length > 0">
        <v-pagination
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          :length="pages"
          :total-visible="7"
          v-model="currentPage"
          color="#283e51"
        ></v-pagination>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs12 v-if="list.length === 0" class="text-center">
        <span style="font-size: 20px; font-weight: bolder"
          >No Bookings Found!</span
        >
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="viewdialog"
      :max-width="
        $vuetify.breakpoint.name == 'lg' || $vuetify.breakpoint.name == 'xl'
          ? '75%'
          : '90%'
      "
    >
      <v-card>
        <v-card-title style="font-family: poppinsregular; font-size: 13px">
          <v-layout wrap justify-center>
            <v-flex xs4 lg2 align-self-start pt-8>
              <v-img
                height="100%"
                contain
                src="../../assets/Images/ibimage12.jpg"
              ></v-img>
            </v-flex>
            <v-flex xs10 pl-4>
              <v-layout class="mainfont" wrap fill-height justify-center pt-5>
                <v-flex xs12>
                  <v-card style="border-radius: 0px" elevation="0">
                    <v-layout wrap>
                      <v-flex xs4 pl-3 v-if="info.guestDetail" text-left>
                        <span style="font-weight: bold; color: #3cb371"
                          >Guest Name<br
                        /></span>
                        {{ info.guestDetail.guestName }}
                      </v-flex>
                      <v-flex xs4>
                        <span style="font-weight: bold; color: #3cb371">
                          Selected Date
                        </span>
                        <br />
                        {{ formatDate(info.fromDate) }} -
                        {{ formatDate(info.toDate) }}
                      </v-flex>

                      <v-flex xs4 pl-3 v-if="info.reqRooms" text-left>
                        <span style="font-weight: bold; color: #3cb371"
                          >Required Rooms<br
                        /></span>
                        {{ info.reqRooms }}
                      </v-flex>
                      <v-flex xs4 pl-3 pt-3 v-if="info.guestDetail" text-left>
                        <span style="font-weight: bold; color: #3cb371"
                          >Mobile Number<br
                        /></span>
                        {{ info.guestDetail.guestMobileNo }}
                      </v-flex>
                      <v-flex xs4 pl-3 pt-3 v-if="info.guestDetail" text-left>
                        <span style="font-weight: bold; color: #3cb371"
                          >Email Address<br
                        /></span>
                        {{ info.guestDetail.guestEmailId }}
                      </v-flex>
                      <v-flex xs4 pl-3 pt-3 v-if="info.guestDetail" text-left>
                        <span style="font-weight: bold; color: #3cb371"
                          >No: Children<br
                        /></span>
                        {{ info.guestDetail.noofchildren }}
                      </v-flex>
                      <v-flex xs4 pl-3 pt-3 v-if="info.guestDetail" text-left>
                        <span style="font-weight: bold; color: #3cb371"
                          >No: Adult<br
                        /></span>
                        {{ info.guestDetail.noofadults }}
                      </v-flex>

                      <v-flex xs4 pl-3 pt-3 v-if="info.guestDetail" text-left>
                        <span style="font-weight: bold; color: #3cb371"
                          >Other Details<br
                        /></span>
                        {{ info.guestDetail.anyOtherDetails }}
                      </v-flex>

                      <v-flex xs4 pl-3 pt-3 v-if="info.guestDetail" text-left>
                        <span style="font-weight: bold; color: #3cb371"
                          >Id Type<br
                        /></span>
                        {{ info.idtype }}
                      </v-flex>
                      <v-flex xs4 pl-3 pt-3 v-if="info.guestDetail" text-left>
                        <span style="font-weight: bold; color: #3cb371"
                          >Id Number<br
                        /></span>
                        {{ info.idNo }}
                      </v-flex>
                      <v-flex xs4 pl-3 pt-3 v-if="info.guestDetail" text-left>
                        <span style="font-weight: bold; color: #3cb371"
                          >Priority<br
                        /></span>
                        {{ info.priority }}
                      </v-flex>

                      <v-flex xs12 pl-3 pt-3 v-if="info.guestDetail" text-left>
                        <span style="font-weight: bold; color: #3cb371"
                          >Id Proof<br
                        /></span>

                        <!-- Iterate over the idfile array and create a button for each PDF -->
                        <v-btn
                          v-for="(pdf, index) in info.idfile"
                          :key="index"
                          color="#3CB043"
                          width="80"
                          dark
                          download
                          :href="baseURL + 'file/get/' + pdf"
                          target="_blank"
                          class="pdf-button"
                        >
                          <span
                            style="
                              text-transform: none;
                              font-family: mainfont;
                              font-size: 10px;
                            "
                            >View Pdf {{ index + 1 }}</span
                          >
                        </v-btn>
                      </v-flex>

                      <v-flex xs12 pl-3 pt-3 v-if="info.priorityDoc" text-left>
                        <span style="font-weight: bold; color: #3cb371"
                          >Priority Id<br
                        /></span>

                        <!-- Iterate over the idfile array and create a button for each PDF -->
                        <v-btn
                          color="#3CB043"
                          width="80"
                          dark
                          download
                          :href="baseURL + 'file/get/' + info.priorityDoc"
                          target="_blank"
                          class="pdf-button"
                        >
                          <span
                            style="
                              text-transform: none;
                              font-family: mainfont;
                              font-size: 10px;
                            "
                            >View Pdf</span
                          >
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="viewdialog = false">close</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="editdialog"
      :max-width="
        $vuetify.breakpoint.name == 'lg' || $vuetify.breakpoint.name == 'xl'
          ? '75%'
          : '90%'
      "
    >
      <v-card>
        <v-card-title style="font-family: poppinsregular; font-size: 13px">
          <v-layout wrap justify-center>
            <v-flex xs4 lg2 align-self-start pt-8>
              <v-img
                height="100%"
                contain
                src="../../assets/Images/ibimage12.jpg"
              ></v-img>
            </v-flex>
            <v-flex xs10 pl-4>
              <v-layout class="mainfont" wrap fill-height justify-center pt-5>
                <v-flex xs12>
                  <v-card style="border-radius: 0px" elevation="0">
                    <v-layout wrap>
                      <v-flex xs4>
                        <span style="font-weight: bold; color: #3cb371">
                          Selected Date
                        </span>
                        <br />
                        {{ formatDate(edit.fromDate) }} -
                        {{ formatDate(edit.toDate) }}
                      </v-flex>
                      <v-flex xs4 v-if="edit.guestDetail">
                        <span style="font-weight: bold; color: #3cb371">
                          Reserved for
                        </span>
                        <br />
                        {{ edit.guestDetail.guestName }}
                      </v-flex>
                      <!-- <v-flex xs4 pt-9 v-if="edit.guestDetail">
    <v-btn   color="#3CB043" dark download
      :href="baseURL + 'file/get/' + edit.idfile" target="_blank"><span
        style="text-transform: none; font-family: mainfont; font-size: 12px;">View Pdf</span></v-btn>
    </v-flex> -->
                      <v-flex xs6 pt-3 v-if="edit.guestDetail">
                        <span style="font-weight: bold; color: #3cb371"
                          >Rooms Required</span
                        >
                        <v-text-field
                          color="black"
                          outlined
                          background-color="white"
                          dense
                          type="text"
                          disabled
                          :rules="roomsRules"
                          v-model="edit.reqRooms"
                          hide-details="auto"
                        >
                        </v-text-field>
                      </v-flex>

                      <v-flex xs6 pt-3 v-if="edit.guestDetail" pl-3>
                        <span style="font-weight: bold; color: #3cb371"
                          >Guest's Phone no.</span
                        >
                        <v-text-field
                          :rules="guestPhoneRules"
                          color="black"
                          outlined
                          background-color="white"
                          dense
                          type="text"
                          v-model="edit.guestDetail.guestMobileNo"
                          hide-details="auto"
                        >
                        </v-text-field>
                      </v-flex>

                      <v-flex xs3 pt-3 v-if="edit.guestDetail">
                        <span style="font-weight: bold; color: #3cb371"
                          >No. of Adults</span
                        >
                        <v-text-field
                          color="black"
                          outlined
                          background-color="white"
                          dense
                          type="text"
                          :rules="noofAdultRules"
                          v-model="edit.guestDetail.noofadults"
                          hide-details="auto"
                        >
                        </v-text-field>
                      </v-flex>

                      <v-flex xs3 pt-3 v-if="edit.guestDetail" pl-3>
                        <span style="font-weight: bold; color: #3cb371"
                          >No. of Childrens</span
                        >
                        <v-text-field
                          color="black"
                          outlined
                          background-color="white"
                          dense
                          type="text"
                          :rules="noofChildrenRules"
                          v-model="edit.guestDetail.noofchildren"
                          hide-details="auto"
                        >
                        </v-text-field>
                      </v-flex>

                      <!-- <v-flex xs6 pt-3>
                            <span style="font-weight: bold; color: #3CB371;">Date</span>
                            <v-menu ref="menu4" v-model="menu4" :close-on-content-click="false" max-width="290">
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field clearable dense outlined readonly v-model="edit.cdate" v-bind="attrs" v-on="on"
                                  @click:clear="from = null"></v-text-field>
                              </template>
                              <v-date-picker v-model="edit.cdate" color="#13736f" @change="menu4 = false"
                                :min="minDate"></v-date-picker>
                            </v-menu>
                          </v-flex> -->

                      <v-flex xs6 pt-3 pl-3 v-if="edit.guestDetail">
                        <span style="font-weight: bold; color: #3cb371"
                          >Email
                        </span>
                        <v-text-field
                          color="black"
                          outlined
                          background-color="white"
                          dense
                          type="text"
                          :rules="emailRules"
                          v-model="edit.guestDetail.guestEmailId"
                          hide-details="auto"
                        >
                        </v-text-field>
                      </v-flex>

                      <v-flex xs6 pt-3 v-if="edit.guestDetail">
                        <span style="font-weight: bold; color: #3cb371"
                          >ID Type
                        </span>
                        <v-text-field
                          color="black"
                          outlined
                          background-color="white"
                          dense
                          type="text"
                          disabled
                          v-model="edit.idtype"
                          hide-details="auto"
                        >
                        </v-text-field>
                      </v-flex>

                      <!-- <v-flex xs6 pt-3 pl-3 v-if="edit.guestDetail">
                            <span style="font-weight: bold; color: #3CB371;">ID Number </span>
                            <v-text-field color="black" outlined background-color="white" dense type="text"
                              :rules="idRules" v-model="edit.idNo" hide-details="auto" >
                            </v-text-field>
                          </v-flex> -->

                      <v-flex xs12 v-if="edit.guestDetail">
                        <span style="font-weight: bold; color: #3cb371"
                          >Other details provided
                        </span>
                        <v-text-field
                          color="black"
                          outlined
                          background-color="white"
                          type="text"
                          v-model="edit.guestDetail.anyOtherDetails"
                          hide-details
                        >
                        </v-text-field>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
              <v-layout wrap v-if="list &&list.length < 1">
                <v-flex xs12 text-center>
                  <span>No Bookings Found !</span>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-layout wrap justify-end>
            <v-flex xs1 pl-5 pt-3>
              <v-btn color="red" text @click="editdialog = false">close</v-btn>
            </v-flex>
            <v-flex xs1 pl-5 pt-3>
              <v-btn color="green" text @click="editItem()">Update</v-btn>
            </v-flex>
          </v-layout>
        </v-card-title>
      </v-card>
    </v-dialog>
    <v-dialog
      :retain-focus="true"
      persistent
      v-model="deletedialog"
      max-width="600px"
    >
      <v-card>
        <v-card-title
          class="mainfont"
          style="color: black; font-size: 18px; font-weight: lighter"
          >Are you sure you want to Cancel?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="black"
            text
            @click="deletedialog = false"
            class="mainfont"
            >Cancel</v-btn
          >
          <v-btn color="red" class="mainfont" text @click="deleteItem()"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      :retain-focus="true"
      persistent
      v-model="submitdialog"
      max-width="800px"
    >
      <v-card>
        <v-card-title
          class="mainfont"
          style="color: black; font-size: 18px; font-weight: lighter"
          >Please enter the details below and proceed for payment.</v-card-title
        >
        <v-layout wrap justify-center>
          <v-flex xs10>
            <v-layout wrap justify-start>
              <v-flex xs12>
                <v-layout wrap justify-start>
                  <v-flex xs12 lg6>
                    <v-text-field
                      type="text"
                      dense
                      clearable
                      outlined
                      class="inputPrice"
                      v-model="paymentname"
                      :rules="[(v) => !!v || 'Name is required']"
                    >
                      <template v-slot:label>
                        <span
                          class="custom-label-text"
                          style="color: black; font-size: 14px"
                        >
                          Name
                          <span style="color: red; font-size: 20px">*</span>
                        </span>
                      </template>
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12 lg6 pl-2>
                    <v-text-field
                      type="text"
                      dense
                      clearable
                      outlined
                      class="inputPrice"
                      v-model="paymentaddress"
                      :rules="[(v) => !!v || 'Address is required']"
                    >
                      <template v-slot:label>
                        <span
                          class="custom-label-text"
                          style="color: black; font-size: 14px"
                        >
                          Address
                          <span style="color: red; font-size: 20px">*</span>
                        </span>
                      </template>
                    </v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout wrap justify-start>
                  <v-flex xs12 lg6>
                    <v-text-field
                      type="number"
                      dense
                      clearable
                      outlined
                      class="inputPrice"
                      v-model="paymentphonenumber"
                      :rules="[
                        (v) => !!v || 'Phone number is required',
                        (v) =>
                          /^[0-9]{10}$/.test(v) ||
                          'Phone number must have 10 digits',
                      ]"
                    >
                      <template v-slot:label>
                        <span
                          class="custom-label-text"
                          style="color: black; font-size: 14px"
                        >
                          Phone Number
                          <span style="color: red; font-size: 20px">*</span>
                        </span>
                      </template>
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12 lg6 pl-2>
                    <v-text-field
                      type="number"
                      dense
                      clearable
                      outlined
                      class="inputPrice"
                      v-model="paymentpincode"
                      :rules="[
                        (v) => !!v || 'Pincode is required',
                        (v) =>
                          /^[0-9]{6}$/.test(v) || 'Pincode must have 6 digits',
                      ]"
                    >
                      <template v-slot:label>
                        <span
                          class="custom-label-text"
                          style="color: black; font-size: 14px"
                        >
                          Pincode
                          <span style="color: red; font-size: 20px">*</span>
                        </span>
                      </template>
                    </v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout wrap justify-start>
                  <v-flex xs12 lg6>
                    <v-text-field
                      type="text"
                      dense
                      clearable
                      outlined
                      class="inputPrice"
                      v-model="paymentpancardnumber"
                      :rules="[
                        (v) => !!v || 'PAN Card Number is required',
                        (v) =>
                          /^[A-Za-z0-9]{10}$/.test(v) ||
                          'PAN Card Number must have exactly 10 characters',
                      ]"
                    >
                      <template v-slot:label>
                        <span
                          class="custom-label-text"
                          style="color: black; font-size: 14px"
                        >
                          PAN Card Number
                          <span style="color: red; font-size: 20px">*</span>
                        </span>
                      </template>
                    </v-text-field>
                  </v-flex>

                  <v-flex xs12 lg6 pl-2>
                    <v-text-field
                      type="number"
                      dense
                      clearable
                      outlined
                      class="inputPrice"
                      v-model="paymenttaxid"
                      :rules="[(v) => !!v || 'TAX Id is required']"
                    >
                      <template v-slot:label>
                        <span
                          class="custom-label-text"
                          style="color: black; font-size: 14px"
                        >
                          TAX Id
                          <span style="color: red; font-size: 20px">*</span>
                        </span>
                      </template>
                    </v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="paymentdialog()" class="mainfont"
            >Cancel</v-btn
          >
          <v-btn
            color="green"
            class="mainfont"
            text
            :disabled="submitted"
            @click="addStudent1()"
          >
            {{ submitted ? "Processing..." : "Proceed For Payment" }}</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
    <script>
import axios from "axios";
import moment from "moment";

export default {
  data() {
    return {
      itemArray2: ["Vegeterian", "Non-vegetarian"],
      // cdate: moment().format("YYYY-MM-DD"),
      curIdToDelete: null,
      name: null,
      editdialog: false,
      barlist: [],
      showDialog: false,
      edit: [],
      userProfileTabs: null,
      subtab: null,
      appLoading: false,
      page: 1,
      keyArray: [],

      pages: 0,
      dialog1: false,
      preview: null,
      tab: null,
      barlist1: [],
      dialog3: false,
      deletedialog: false,
      minAmount: null,
      password: null,
      categoryarray: [],
      getdetailsdata: "",
      list: [],
      flag: false,
      flag2: false,
      auctionbased: [],
      currentpage: 1,
      menu1: false,
      cdate: null,
      menu2: false,
      menu3: false,
      menu4: false,
      fromDate: null,
      toDate: null,
      fromDate2: null,
      toDate2: null,
      msg: null,
      submitdialog: false,
      currentPage: 1,
      showSnackBar: false,
      info: [],
      dialog2: false,
      viewdialog: false,
      paymentphonenumber: "",
      paymentpancardnumber: "",
      paymentname: "",
      paymenttaxid: "",
      paymentpincode: "",
      paymentaddress: "",
      submitted: false,
    };
  },

  mounted() {
    this.getList();
  },
  watch: {
    currentPage() {
      this.getList();
    },
    fromDate() {
      this.getList();
    },
    toDate() {
      this.getList();
    },
  },
  computed: {
    guestPhoneRules() {
      return [
        (v) => !!v || "Phone number is required",
        (v) => /^[0-9]{10}$/.test(v) || "Phone number must be 10 digits",
      ];
    },
    emailRules() {
      return [
        (v) => !!v || "E-mail is required",
        (v) =>
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(v) ||
          "E-mail must be valid",
      ];
    },
    idRules() {
      return [
        (v) => !!v || "ID is required",
        (v) =>
          /^[a-zA-Z0-9]*$/.test(v) || "ID must not contain special characters",
      ];
    },
    noofChildrenRules() {
      return [
        (v) => !!v || "Number of children is required",
        (v) => /^[0-9]*$/.test(v) || "Number of children must be a number",
      ];
    },
    noofAdultRules() {
      return [
        (v) => !!v || "Number of Adult is required",
        (v) => /^[0-9]*$/.test(v) || "Number of Adult must be a number",
      ];
    },
    roomsRules() {
      return [
        (v) => !!v || "Rooms Required is required",
        (v) => /^[0-9]*$/.test(v) || "Rooms Required must be a number",
      ];
    },
    minDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0");
      const day = String(today.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    formattedDate() {
      const date = new Date(this.edit.selectedDate);
      return date.toLocaleString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    },
  },

  methods: {
    setCurIdToDelete(id) {
      this.curIdToDelete = id;
    },
    openPaymentDialog(item) {
      this.item = item;
      this.submitdialog = true;
    },
    paymentdialog() {
      this.submitdialog = false;
      this.submitted = false;
      this.paymentphonenumber = "";
      this.paymentname = "";
      this.paymentpancardnumber = "";
      this.paymentaddress = "";
      this.paymentpincode = "";
      this.paymenttaxid = "";
    },
    cancelPayment() {
      this.showDialog = false; // Close the dialog
    },
    confirmPayment() {
      this.showDialog = false; // Close the dialog
      // Add your payment logic here
      console.log("Payment confirmed");
    },

    deleteItem() {
      const idToDelete = this.curIdToDelete;

      if (!idToDelete) {
        return;
      }

      axios({
        url: "/cancel/booking",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          bookingid: idToDelete,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.deletedialog = false;
            window.location.reload();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    editItem() {
      axios({
        url: "/edit/booking",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          bookingid: this.edit._id,
          reqRooms: this.edit.guestDetail.reqRooms,
          fromDate: this.edit.guestDetail.fromDate,
          toDate: this.edit.guestDetail.toDate,
          guestEmailId: this.edit.guestDetail.guestEmailId,
          noofadults: this.edit.guestDetail.noofadults,
          noofchildren: this.edit.guestDetail.noofchildren,
          guestMobileNo: this.edit.guestDetail.guestMobileNo,

          anyOtherDetails: this.edit.guestDetail.anyOtherDetails,

          idNo: this.edit.guestDetail.idNo,
          idtype: this.edit.guestDetail.idtype,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.editdialog = false;
            window.location.reload();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    addStudent1() {
      if (!this.paymentname) {
        this.msg = "Provide Name For Payment Procedure.";
        this.showSnackBar = true;
        return;
      }
      if (!this.paymentaddress) {
        this.msg = "Provide Address For Payment Procedure.";
        this.showSnackBar = true;
        return;
      }
      if (!this.paymentphonenumber) {
        this.msg = "Provide Phone Number For Payment Procedure.";
        this.showSnackBar = true;
        return;
      }
      if (!this.paymentpincode) {
        this.msg = "Provide Pincode For Payment Procedure.";
        this.showSnackBar = true;
        return;
      }
      if (!this.paymentpancardnumber) {
        this.msg = "Provide PAN Card Number For Payment Procedure.";
        this.showSnackBar = true;
        return;
      }
      if (!this.paymenttaxid) {
        this.msg = "Provide Tax Id For Payment Procedure.";
        this.showSnackBar = true;
        return;
      }
      if (!/^[0-9]{10}$/.test(this.paymentphonenumber)) {
        this.msg = "Phone Number must be 10 digits.";
        this.showSnackBar = true;
        return;
      }
      if (!/^[0-9]{6}$/.test(this.paymentpincode)) {
        this.msg = "Pincode must be 6 digits.";
        this.showSnackBar = true;
        return;
      }
      if (!/^[A-Za-z0-9]{10}$/.test(this.paymentpancardnumber)) {
        this.msg = "PAN Card Number must have exactly 10 characters.";
        this.showSnackBar = true;
        return;
      }
      this.submitted = true;
      axios({
        url: "/add/booking",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.item._id,
          amount: this.item.amount,
          address: this.paymentaddress,
          mobnumber: this.paymentphonenumber,
          pin_no: this.paymentpincode,
          pan_no: this.paymentpancardnumber,
          tax_id: this.paymenttaxid,
          party_name: this.paymentname,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.getdetailsdata = response.data.data;
            this.openFormInNewWindow();
            this.submitdialog = false;
            this.$router.push({ path: "/UserMybookings" });
            // window.location.reload();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    openFormInNewWindow() {
      // Create a form element
      const form = document.createElement("form");
      form.method = "POST";
      form.action =
        "https://etreasury.kerala.gov.in/api/eTreasury/service/ChallanGeneration.php"; // Replace with your actual endpoint URL
      form.target = "_blank"; // Open in a new window or tab

      // Create hidden input fields for 'input_data' and 'input_headers'
      const inputDataField = document.createElement("input");
      inputDataField.type = "hidden";
      inputDataField.name = "input_data";
      inputDataField.value = JSON.stringify({
        HMAC: this.getdetailsdata.HMAC, // Replace with actual HMAC value
        DATA: this.getdetailsdata.DATA,

        // Replace with actual encrypted data
      });

      const inputHeadersField = document.createElement("input");
      inputHeadersField.type = "hidden";
      inputHeadersField.name = "input_headers";
      inputHeadersField.value = JSON.stringify({
        clientId: this.getdetailsdata.clientId,
        authToken: this.getdetailsdata.authToken,
        // Replace with actual auth token
      });

      // Append hidden inputs to the form
      form.appendChild(inputDataField);
      console.log("inputDataField", inputDataField);
      form.appendChild(inputHeadersField);
      console.log("inputHeadersField", inputHeadersField);

      // Append form to the body (required to submit it)
      document.body.appendChild(form);

      // Submit the form
      form.submit();

      // Remove the form from the DOM after submission
      document.body.removeChild(form);
    },
    getInfo(id) {
      axios({
        method: "POST",
        url: "/single/booking",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          bookingid: id,
        },
      })
        .then((response) => {
          this.info = response.data.data;
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var month = dt.getMonth() + 1; // Month is zero-based, so we add 1
      var year = dt.getFullYear();

      // Pad single digit day and month with leading zero if necessary
      var formattedDay = day < 10 ? "0" + day : day;
      var formattedMonth = month < 10 ? "0" + month : month;

      return formattedDay + "-" + formattedMonth + "-" + year;
    },
    getList() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/ibmanager/allbooking/view",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          // status: this.status,
          // categoryId: this.categoryId,
          page: this.currentPage,
          fromDate: this.fromDate,
          // user: "yes",
          toDate: this.toDate,
          limit: 10,
        },
      })
        .then((response) => {
          if(response.data.status){
            this.msg = response.data.message;
            this.showSnackBar = true;
          
          this.list = response.data.data.map((item) => {
            const date = new Date(item.selectedDate);
            item.selectedDate = date.toLocaleDateString();
            item.cdate = moment(item.selectedDate).format("YYYY-MM-DD");
            return item;
          });
          this.totalData = response.data.totalLength;
          this.selectedDatesArray = this.list.map((item) => item.selectedDate);
          this.pages = Math.ceil(this.totalData / response.data.limit);
          this.appLoading = false;
        }
          else{
            this.msg = response.data.message;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
  
  <style scoped>
.pdf-button {
  margin-right: 10px; /* Adjust the value as needed for the desired gap */
}
.no-spinners {
  /* Remove the up and down arrows */
  appearance: textfield;
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
}
.inputPrice input::-webkit-outer-spin-button,
.inputPrice input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>
      
    